import React from "react"

import GenericHero from "../components/GenericHero"
import SiteHeader from "../components/SiteHeader"
import TopImageContainer from "../components/TopImageContainer"

import TopImage from "../images/top_yum_bg.png"
import BrandsWeChangedSection from "../components/BrandsWeChangedSection"
import LetsConnectSection from "../components/LetsConnectSection"
import SiteFooter from "../components/SiteFooter"
import content from "../data/Content"


export default function Work() {
  let page = "work"
  let data = content(page)
  return <div className="content">
  <TopImageContainer image={TopImage}>
    <SiteHeader  page={page} content={content("header")} />
    <GenericHero content={data}  />
  </TopImageContainer>
  <BrandsWeChangedSection content={content(page)} />
  <LetsConnectSection content={content(page)} />
  <SiteFooter content={content("footer")} />
</div> 
  
}